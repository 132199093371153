<template>
    <div class="dashboard-logged mainWrapper bg-white">
        <section class="blockElement innerMenu py-3 pt-5 bg-white">
           <div class="container max-1370">
                <h1 class="f-25 bold letter-space-0 text-center line-1">{{$t('broker.Welcome')}}</h1>
                <p class="text-center">{{$t('broker.start_trading')}}</p>
                <div class="row mt-4 pt-md-4">
                    <!-- <div class="col-12">
                        <p>{{$t('broker.pick')}}</p>
                    </div> -->
                    <div class="col-12 d-flex align-items-center justify-content-between colFeed">
                        <div class="newFillTabs overflow-auto megaDropMenu">
                            <a href="javascript:void(0)" class="button fillBtn zulu_btn mobButoon d-inline-flex align-items-center justify-content-between d-md-none" @click="addActive = !addActive">
                                {{integrationMode == 'ALL' ? $t('changes.text33') : (integrationMode == 'INTEGRATED') ? $t('changes.text19') : $t('changes.text20')}}
                                <vue-feather size="18" type="chevron-down" class="ms-3"></vue-feather>
                            </a>
                            <ul id="tabs-nav" class="zuluTabs d-flex align-items-center px-0 mb-0" :class="[{ active: addActive }]">
                                <li :class="(integrationMode == 'ALL')?'active':''">
                                    <a @click.prevent="integrationMode = 'ALL';zuluGrid = 'tab1';addActive = false" href="javascript:void(0)" class="switchToggle d-flex left uppercase"> {{$t('changes.text33')}} </a>
                                </li>
                                <li :class="(integrationMode == 'INTEGRATED')?'active':''">
                                    <a @click.prevent="integrationMode = 'INTEGRATED';zuluGrid = 'tab1';addActive = false" href="javascript:void(0)" class="switchToggle d-flex left uppercase"> {{$t('changes.text19')}} </a>
                                </li>
                                <li :class="(integrationMode == 'CO_BRANDED')?'active':''">
                                    <a @click.prevent="integrationMode = 'CO_BRANDED';zuluGrid = 'tab1';addActive = false"  href="javascript:void(0)" class="switchToggle d-flex right uppercase"> {{$t('changes.text20')}} </a>
                                </li>
                            </ul>
                        </div>
                        <div class="gridList">
                            <ul id="tabs-nav" class="mb-0 toggleSwitch-layout d-flex align-items-center p-0">
                                <li class="d-flex">
                                    <a @click.prevent="zuluGrid = 'tab1'" :class="(zuluGrid == 'tab1')?'active':''" href="javascript:void(0)" class="switchToggle d-flex left"> <vue-feather type="list" size="30"></vue-feather> </a>
                                </li>
                                <li class="d-flex">
                                    <a @click.prevent="zuluGrid = 'tab2'" :class="(zuluGrid == 'tab2')?'active':''"  href="javascript:void(0)" class="switchToggle d-flex right"> <vue-feather type="grid" size="30"></vue-feather> </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space innerMenu pt-2 bg-white">
           <div class="container max-1370">
               <div class="row">
                    <div class="col-12">
                        <div id="tab1" class="tab-content zuluTableBroker" v-if="zuluGrid == 'tab1' && getBrokersTabwise.length">
                            <div class="paidBroker mb-4" v-if="integrationMode == 'INTEGRATED'">
                                <h6>Integrated Brokers</h6>
                                <div class="table-responsive">
                                    <table class="table table-bordered mb-0 investors-table">
                                        <thead>
                                            <tr>
                                                <th class="rootTable"></th>
                                                <th>{{$t('changes.text24')}}</th>
                                                <th>{{$t('broker.platform')}}</th>
                                                <th>{{$t('broker.regulate')}}</th>
                                                <th>{{$t('broker.min_deposit')}}</th>
                                                <th>{{$t('broker.supp')}}</th>
                                                <!-- <th>{{$t('changes.text21')}}</th> -->
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="list,key in getBrokersTabwise" :key="key">
                                                <td class="rootTable p-0">
                                                    <div class="d-flex justify-content-center align-items-center brokerLogo">
                                                        <a :href="list.website" target="_blank" class="darkMode d-flex align-items-center justify-content-center modWidth">
                                                            <v-lazy-image width="90" height="30" :src="`/assets/images/clientLogo/${list.logoUrl.split('/')[3].split('.')[0]}.svg`" :alt="list.name" :title="list.name" />
                                                        </a>
                                                        <!-- <span class="globEU pinkBtn">{{list.flavor || ''}}</span> -->
                                                    </div>
                                                </td>
                                                <td :class="(taskTab == '1')?'hideActive':''"  class="text-center">{{list.flavor || ''}}</td>
                                                <td :class="(taskTab == '1')?'hideActive':''"  class="text-center">{{list?.platforms.join(',') || '-'}}</td>
                                                <td :class="(taskTab == '1')?'hideActive':''" class="text-center">{{list?.regulationCountry[0] || '-'}}</td>
                                                <td :class="(taskTab == '1')?'hideActive':''"  class="text-center">${{list.minimumDeposit || '-'}}</td>
                                                <td :class="(taskTab == '1')?'hideActive':''" class="text-center">{{list?.availableCurrencies.join(',') || '-'}}</td>
                                                <!-- <td :class="(taskTab == '1')?'hideActive':''" class="text-center">{{list?.freeCopyTrading ? $t('changes.text22') : $t('changes.text23')}}</td> -->
                                                <td class="text-center">
                                                    <a class="button fillBtn" :href="store.user?.access_token ? '/select-a-broker' : 'javascript:void(0)'" @click="deepLink()">{{$t('broker.select')}}</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="paidBroker" v-if="integrationMode == 'INTEGRATED'">
                                <h6>Co-Branded Brokers</h6>
                                <div class="table-responsive">
                                    <table class="table table-bordered mb-0 investors-table">
                                        <thead>
                                            <tr>
                                                <th class="rootTable"></th>
                                                <th>{{$t('changes.text24')}}</th>
                                                <th>{{$t('broker.platform')}}</th>
                                                <th>{{$t('broker.regulate')}}</th>
                                                <th>{{$t('broker.min_deposit')}}</th>
                                                <th>{{$t('broker.supp')}}</th>
                                                <!-- <th>{{$t('changes.text21')}}</th> -->
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="list,key in getBrokersTabwise1" :key="key">
                                                <td class="rootTable p-0">
                                                    <div class="d-flex justify-content-center align-items-center brokerLogo">
                                                        <a :href="list.website" target="_blank" class="darkMode d-flex align-items-center justify-content-center modWidth">
                                                            <v-lazy-image width="90" height="30" :src="`/assets/images/clientLogo/${list.logoUrl.split('/')[3].split('.')[0]}.svg`" :alt="list.name" :title="list.name" />
                                                        </a>
                                                        <!-- <span class="globEU pinkBtn">{{list.flavor || ''}}</span> -->
                                                    </div>
                                                </td>
                                                <td :class="(taskTab == '1')?'hideActive':''"  class="text-center">{{list.flavor || ''}}</td>
                                                <td :class="(taskTab == '1')?'hideActive':''"  class="text-center">{{list?.platforms.join(',') || '-'}}</td>
                                                <td :class="(taskTab == '1')?'hideActive':''" class="text-center">{{list?.regulationCountry[0] || '-'}}</td>
                                                <td :class="(taskTab == '1')?'hideActive':''"  class="text-center">${{list.minimumDeposit || '-'}}</td>
                                                <td :class="(taskTab == '1')?'hideActive':''" class="text-center">{{list?.availableCurrencies.join(',') || '-'}}</td>
                                                <!-- <td :class="(taskTab == '1')?'hideActive':''" class="text-center">{{list?.freeCopyTrading ? $t('changes.text22') : $t('changes.text23')}}</td> -->
                                                <td class="text-center">
                                                    <a class="button fillBtn" :href="store.user?.access_token ? '/select-a-broker' : 'javascript:void(0)'" @click="deepLink()">{{$t('broker.select')}}</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-bordered mb-0 investors-table" v-if="integrationMode != 'INTEGRATED'">
                                    <thead>
                                        <tr>
                                            <th class="rootTable"></th>
                                            <th>{{$t('changes.text24')}}</th>
                                            <th>{{$t('broker.platform')}}</th>
                                            <th>{{$t('broker.regulate')}}</th>
                                            <th>{{$t('broker.min_deposit')}}</th>
                                            <th>{{$t('broker.supp')}}</th>
                                            <!-- <th>{{$t('changes.text21')}}</th> -->
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="list,key in getBrokersTabwise" :key="key">
                                            <td class="rootTable p-0">
                                                <div class="d-flex justify-content-center align-items-center brokerLogo">
                                                    <a :href="list.website" target="_blank" class="darkMode d-flex align-items-center justify-content-center modWidth">
                                                        <v-lazy-image width="90" height="30" :src="`/assets/images/clientLogo/${list.logoUrl.split('/')[3].split('.')[0]}.svg`" :alt="list.name" :title="list.name" />
                                                    </a>
                                                    <!-- <span class="globEU pinkBtn">{{list.flavor || ''}}</span> -->
                                                </div>
                                            </td>
                                            <td :class="(taskTab == '1')?'hideActive':''"  class="text-center">{{list.flavor || ''}}</td>
                                            <td :class="(taskTab == '1')?'hideActive':''"  class="text-center">{{list?.platforms.join(',') || '-'}}</td>
                                            <td :class="(taskTab == '1')?'hideActive':''" class="text-center">{{list?.regulationCountry[0] || '-'}}</td>
                                            <td :class="(taskTab == '1')?'hideActive':''"  class="text-center">${{list.minimumDeposit || '-'}}</td>
                                            <td :class="(taskTab == '1')?'hideActive':''" class="text-center">{{list?.availableCurrencies.join(',') || '-'}}</td>
                                            <!-- <td :class="(taskTab == '1')?'hideActive':''" class="text-center">{{list?.freeCopyTrading ? $t('changes.text22') : $t('changes.text23')}}</td> -->
                                            <td class="text-center">
                                                <a class="button fillBtn" :href="store.user?.access_token ? '/select-a-broker' : 'javascript:void(0)'" @click="deepLink()">{{$t('broker.select')}}</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div id="tab2" class="tab-content" v-if="zuluGrid == 'tab2' && getBrokersTabwise.length">
                            <div class="invester-broker-list">
                                <div class="paidBroker text-center mb-4" v-if="integrationMode == 'INTEGRATED'">
                                    <h6>Integrated Brokers</h6>
                                    <ul>
                                        <li class="darkBG" v-for="list,key in getBrokersTabwise" :key="key">
                                            <a :href="list.website" target="_blank" class="d-block">
                                                <v-lazy-image width="120" height="30" :src="`/assets/images/clientLogo/${list.logoUrl.split('/')[3].split('.')[0]}.svg`" :alt="list.name" :title="list.name" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="paidBroker text-center" v-if="integrationMode == 'INTEGRATED'">
                                    <h6>Co-Branded Brokers</h6>
                                    <ul>
                                        <li class="darkBG" v-for="list,key in getBrokersTabwise1" :key="key">
                                            <a :href="list.website" target="_blank" class="d-block">
                                                <v-lazy-image width="120" height="30" :src="`/assets/images/clientLogo/${list.logoUrl.split('/')[3].split('.')[0]}.svg`" :alt="list.name" :title="list.name" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <ul v-if="integrationMode != 'INTEGRATED'">
                                    <li class="darkBG" v-for="list,key in getBrokersTabwise" :key="key">
                                        <a :href="list.website" target="_blank" class="d-block">
                                            <v-lazy-image width="120" height="30" :src="`/assets/images/clientLogo/${list.logoUrl.split('/')[3].split('.')[0]}.svg`" :alt="list.name" :title="list.name" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
               </div>
           </div>
        </section>
        <section class="blockElement pt-0 space bg-white">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-6">
                        <div class="cantFind d-flex align-items-center justify-content-between flex-wrap">
                            <h3 class="mb-0">{{$t('broker.can_not')}}</h3>
                            <button class="button fillBtn py-2 px-4" type="button" :class="{'disabled' : store.customerDetail?.readOnly}" @click="raiseQuery = true">{{$t('broker.request')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
   </div>
<!-- MODAL START View IFRAME -->
<div class="modal fade" id="staticBackdrop" aria-labelledby="staticBackdropLabel">
   <div class="modal-dialog modal-dialog-centered">
       <div class="modal-content">
           <div class="modal-header">
               <p class="mb-0 f-20 fw-300">{{$t('broker.register')}}</p>
               <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
           </div>
           <div class="modal-body row">
               <p class="text-center">{{$t('broker.need')}}</p>
           </div>
           <div class="d-flex align-items-center justify-content-end px-3 pb-3">
               <a :href="'/register/'" type="button" class="button fillBtn f-14">{{$t('broker.create')}}</a>
           </div>
       </div>
   </div>
</div>
<!-- End -->
<div class="modal fade muySellModal" :class="[{'show' : raiseQuery}]">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header border-0">
                <div class="d-flex align-items-center justify-content-center">
                    <div class="toggleSlidebutton d-inline-flex align-items-center justify-content-center">
                        <h6 class="bold mb-0">{{$t('broker.add_request')}}</h6>
                    </div>
                </div>
                <button type="button" class="btn-close" @click="raiseQuery = false"></button>
            </div>
            <div class="modal-body pb-2">
                <div class="specificPrice">
                     <div class="investmentAmount my-2">
                        <!-- <h6 class="mb-2 f-15 regular">Broker</h6> -->
                            <div class="form-group position-relative">
                                 <Form @submit="raiseQueryFunc()">
                                    <float-label class="countrySelect form-group position-relative" :class="[{'selected' : query.broker}]">
                                        <Field
                                          class="form-control"
                                          name="broker name"
                                          :placeholder="$t('broker.name')"
                                          v-model="query.broker"
                                          rules="required"
                                          autocomplete="off"
                                        />
                                          <!-- <label class="afterSelect">Broker Name</label> -->
                                        <ErrorMessage name="broker name" class="text-danger f-14" />
                                    </float-label>
                                    <float-label class="countrySelect form-group position-relative" :class="[{'selected' : query.brokerUrl}]">
                                        <Field
                                          class="form-control"
                                          name="broker url"
                                          :placeholder="$t('broker.url')"
                                          v-model="query.brokerUrl"
                                          autocomplete="off"
                                        />
                                        <!-- <label class="afterSelect">Broker URL</label> -->
                                        <!-- <ErrorMessage name="broker url" class="text-danger" /> -->
                                    </float-label>
                                     <float-label class="countrySelect form-group position-relative" :class="[{'selected' : query.info}]">
                                        <Field
                                            as="textarea"
                                            class="form-control textarea"
                                            name="additional info"
                                            :placeholder="$t('broker.info')"
                                            v-model="query.info"
                                            autocomplete="off"
                                        />
                                          <!-- <label class="afterSelect">Additional Info</label> -->
                                        <!-- <ErrorMessage name="additional info" class="text-danger" /> -->
                                    </float-label>
                                    <div class="buttonElement mb-3">
                                        <button type="submit" class="button fillBtn py-2 px-4">{{$t('broker.submit')}}</button>
                                    </div>
                                </Form>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<loginPopup v-if="showLoginPopup"></loginPopup>
</template>
<script>
import { myStore } from "@/store/pinia-store";
import FloatLabel from 'vue-float-label/components/FloatLabel'
import { Form, Field, ErrorMessage } from "vee-validate";
   export default {
    setup() {
        const store = myStore();
        return { store };
    },
       data(){
           return {
               showLoginPopup: false,
               taskTab:'',
               zuluGrid:'tab1',
               raiseQuery : false,
               addActive : false,
                query : {
                    broker : '',
                    info : '',
                    brokerUrl : ''
                },
                integrationMode : 'ALL'
           }
       },
       components: {
            FloatLabel,
            Form,
            Field,
            ErrorMessage,
        },
        computed: {
            getBrokersTabwise(){
                if(this.store.allBrokersList.length){
                    if(this.integrationMode == 'INTEGRATED'){
                        return this.store.allBrokersList.filter(v=>v.integrationMode == this.integrationMode);
                    }else if(this.integrationMode == 'CO_BRANDED'){
                        // return this.store.allBrokersList.filter(v=>v.integrationMode != 'INTEGRATED' && !v.freeCopyTrading);
                        return this.store.allBrokersList.filter(v=>v.integrationMode != 'INTEGRATED');
                    } else {
                        return this.store.allBrokersList
                    }
                }else{
                    return []
                }
            },
            getBrokersTabwise1(){
                if(this.store.allBrokersList.length){
                    if(this.integrationMode == 'INTEGRATED'){
                        return this.store.allBrokersList.filter(v=>v.integrationMode == 'CO_BRANDED' && v.freeCopyTrading);
                    }else {
                        return this.store.allBrokersList
                    }
                }else{
                    return []
                }
            }
        },
        methods:{
            disableButton(type){
                if(Object.keys(this.store.flavordata).length && this.store.flavordata.flavorId){
                    if(parseInt(this.store.flavordata.flavorId) == 1 && type == 'global'){
                        return false
                    } else if(parseInt(this.store.flavordata.flavorId) == 38 && type == 'eu'){
                        return false
                    } else {
                        return true
                    }
                }
            },
            raiseQueryFunc(){
                let form = {
                    'name' : this.query.broker
                }
                if(this.query.brokerUrl){
                    form['url'] = this.query.brokerUrl
                }
                if(this.query.info){
                    form['additionalInfo'] = this.query.info
                }
                this.store.brokerRaiseQuery(form,true).then(response =>{
                    if(response === true){
                        this.raiseQuery = false
                        this.query = {
                            broker : '',
                            info : '',
                            brokerUrl : ''
                        }
                    }
                })
            },
            deepLink(){
                if(this.store.user?.access_token){ return false }
                else { this.showLoginPopup = true }
            }
        },
        created(){
            this.store.getAllBrokersList({},true,'withoutFlavor')
            if(this.$route.query?.type){
                if(this.$route.query.type == 'integrated'){
                    this.integrationMode = 'INTEGRATED'
                }else if(this.$route.query.type == 'co-branded'){
                    this.integrationMode = 'CO_BRANDED'
                }
                this.$router.push({query :{}})
            }
        }
   }
</script>